import { Form, Input } from "antd";

export function ExpiryForm({ form, handleSubmit }) {
  return (
    <Form
      form={form}
      name="loginForm"
      onFinish={handleSubmit}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      style={{
        maxWidth: 600,
      }}
    >
      <Form.Item
        name="NIFTY_TICK"
        label="Enter TExpiry"
        rules={[
          {
            required: true,
            message: "Please add expiry!",
          },
        ]}
      >
        <Input placeholder="Enter tick expiry  (2025-02-20) " />
      </Form.Item>
      <Form.Item
        name="NIFTY"
        label="Enter Expiry"
        rules={[
          {
            required: true,
            message: "Please add expiry!",
          },
        ]}
      >
        <Input placeholder="Enter order expiry ( 25220 )" />
      </Form.Item>
    </Form>
  );
}

export default ExpiryForm;
