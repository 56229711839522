const navigationItems = [
  { path: "/live", name: "Dashboard" },
  { path: "/chart", name: "Chart" },
  { path: "/orders", name: "Orders" },
  { path: "/users", name: "Users" },
  { path: "/console", name: "Console" },
  { path: "/balance", name: "Balance" },
];

// eslint-disable-next-line
export default {
  navigationItems,
};
