import dayjs from "dayjs";

// eslint-disable-next-line
export default {
  column: (ordersLength, handleDelete) => [
    {
      title: "Sl",
      dataIndex: "_id",
      key: "_id",
      render: (_id, _data, idx) => {
        return <span>{ordersLength - idx}</span>;
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text) => dayjs(text).format("ddd D MMM YYYY"),
    },
    {
      title: "Capital",
      dataIndex: "capital",
      key: "capital",
      render: (data) => {
        return (
          <span style={{ color: "#4988ee" }}>
            {data ? data?.toLocaleString("en-IN") : "-"}
          </span>
        );
      },
    },
    {
      title: "BQTY",
      dataIndex: "buyQunatity",
      key: "buyQunatity",
      render: (data) => {
        return (
          <span className="profit">
            {" "}
            {data ? data?.toLocaleString("en-IN") : "-"}
          </span>
        );
      },
    },
    {
      title: "SQTY",
      dataIndex: "sellQuantity",
      key: "sellQuantity",
      render: (data) => {
        return (
          <span className="loss">
            {" "}
            {data ? data?.toLocaleString("en-IN") : "-"}
          </span>
        );
      },
    },
    {
      title: "Tax",
      dataIndex: "charges",
      key: "charges",
      render: (data) => {
        return (
          <span className="loss">
            {data ? data?.toLocaleString("en-IN") : "-"}
          </span>
        );
      },
    },
    {
      title: "Profit",
      dataIndex: "profit",
      key: "profit",
      render: (data) => {
        return (
          <span className={`${data > 0 ? "profit" : "loss"}`}>
            {data ? data?.toLocaleString("en-IN") : "-"}
          </span>
        );
      },
    },
    {
      title: "Net Profit",
      dataIndex: "totalProfit",
      key: "totalProfit",
      render: (data) => {
        return (
          <span className={`${data > 0 ? "profit" : "loss"}`}>
            {data ? data?.toLocaleString("en-IN") : "-"}
          </span>
        );
      },
    },
    {
      title: "Capture",
      dataIndex: "captured",
      key: "captured",
      render: (data) => {
        return (
          <span className={`${data > 0 ? "profit" : "loss"}`}>
            {data || "-"}
          </span>
        );
      },
    },
    {
      title: "Profit %",
      dataIndex: "profitPercentage",
      key: "profitPercentage",
      render: (data) => {
        return (
          <span className={`${data > 0 ? "profit" : "loss"}`}>
            {data || "-"}%
          </span>
        );
      },
    },
  ],
};
