import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { getIntialData } from "../../utils/api";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

const MAX_CANDLES = 500; // Define a max limit for stored candles

const StockVarianceChart = ({
  indexType,
  chartType = "nsignal",
  title,
  selectedDate,
}) => {
  const candlesRef = useRef([]); // Store candles without causing re-renders
  const [candles, setCandles] = useState([]); // State to trigger updates
  const eventSourceRef = useRef(null); // Store EventSource instance
  const chartRef = useRef(null); // Reference for Chart.js

  // Function to process and update new data efficiently
  const processNewData = useCallback((newData) => {
    candlesRef.current = [...candlesRef.current, newData].slice(-MAX_CANDLES);
    setCandles([...candlesRef.current]); // Trigger re-render with the latest data
  }, []);

  useEffect(() => {
    if (!eventSourceRef.current) {
      eventSourceRef.current = new EventSource(process.env.REACT_APP_PL_SERVER);
    }

    const handleEvent = (event) => {
      const data = JSON.parse(event.data);
      if (data?.graph) {
        const { label, ...rest } = data.graph;
        const newCandle = {
          label: new Date(parseInt(label, 10)) // Ensure timestamp is converted correctly
            .toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }), // Format HH:MM
          ...rest,
        };
        if (
          !selectedDate ||
          new Date().getDate() === new Date(selectedDate).getDate()
        ) {
          processNewData(newCandle);
        }
      }
    };

    eventSourceRef.current.onmessage = handleEvent;

    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
        eventSourceRef.current = null;
      }
    };
  }, [processNewData, selectedDate]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getIntialData(selectedDate);
      if (data?.data) {
        candlesRef.current = data.data.map((ele) => ({
          label: new Date(parseInt(ele.label, 10)).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          ...ele,
        }));
        setCandles([...candlesRef.current]);
      }
    };
    fetchData();
  }, [indexType, selectedDate]);

  // Memoized stock list for improved performance
  const allStocks = useMemo(() => {
    if (!candles.length) return [];
    const stocksSet = new Set();
    for (const candle of candles) {
      if (candle?.[indexType]) {
        Object.keys(candle[indexType]).forEach((stock) => stocksSet.add(stock));
      }
    }
    return [...stocksSet];
  }, [candles, indexType]);

  // Memoized dataset for performance optimization
  const datasets = useMemo(() => {
    return allStocks.map((stock) => {
      const stockData = candles.map(
        (candle) => candle?.[indexType]?.[stock]?.value || 0
      );
      const backgroundColor = candles.map((candle) =>
        candle?.[indexType]?.[stock]?.[chartType] === "SELL"
          ? "red"
          : candle?.[indexType]?.[stock]?.[chartType] === "BUY"
          ? "#3ba407"
          : "blue"
      );
      return {
        label: stock,
        data: stockData,
        backgroundColor,
        borderColor: "white",
        borderWidth: 0.5,
      };
    });
  }, [candles, indexType, allStocks, chartType]);

  // Memoized chart data
  const data = useMemo(
    () => ({
      labels: candles.map((candle) => {
        return !isNaN(candle.label)
          ? new Date(candle.label).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })
          : candle.label;
      }),
      datasets,
    }),
    [candles, datasets]
  );

  // Memoized chart options
  const options = useMemo(
    () => ({
      responsive: true,
      plugins: {
        legend: { position: "top", display: false },
        title: { display: true, text: title },
        tooltip: {
          callbacks: {
            label: (tooltipItem) => {
              const stockName = tooltipItem.dataset.label;
              const candle = candles[tooltipItem.dataIndex];
              const vwap = candle?.[indexType]?.[stockName]?.vwap || "N/A";
              const upperBand =
                candle?.[indexType]?.[stockName]?.upperBand || "N/A";
              const lowerBand =
                candle?.[indexType]?.[stockName]?.lowerBand || "N/A";
              return `${stockName} | (V: ${vwap}) | (D2: ${upperBand}) | (-D2: ${lowerBand})`;
            },
          },
        },
      },
      scales: {
        x: { stacked: true, title: { display: true, text: "Candles" } },
        y: { stacked: true, title: { display: true, text: "Variance" } },
      },
    }),
    [candles, title, indexType]
  );

  return <Bar ref={chartRef} height={90} data={data} options={options} />;
};

export default StockVarianceChart;
