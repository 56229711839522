import { useState } from "react";
import StockPercentageChart from "./StackedBar";
import { DatePicker, Table } from "antd";
import Heatmap from "../heatmap";
import Pulse from "../pulse";
import "./style.css";
import { useOutletContext } from "react-router-dom";
import C from "./constant";
import dayjs from "dayjs";

function Chart() {
  const [current, setCurrent] = useState("Tick");
  const { tickData } = useOutletContext();
  const [selectedDate, setSelectedDate] = useState(null);
  const currentDate = dayjs(new Date()); // Convert JS Date to dayjs

  const getChange = (number) => {
    if (!number) return "-";
    const fixedNumber = parseFloat(
      number.toString().split(".")[0] +
        "." +
        number.toString().split(".")[1].slice(0, 2)
    );
    return fixedNumber;
  };

  const getNiftyPrice = () => {
    return (
      <>
        NIFTY50:{" "}
        <span className={tickData[0]?.change < 0 ? "loss" : "profit"}>
          {tickData[0]?.ltp?.toLocaleString() || "-"} &nbsp;{" "}
          {getChange(tickData[0]?.change) > 0 ? "+" : ""}
          {getChange((tickData[0]?.ltp * tickData[0]?.change) / 100) || "-"}
          &nbsp;( {getChange(tickData[0]?.change) > 0 ? "+" : ""}
          {getChange(tickData[0]?.change) || "-"}%)
        </span>
        <br />
        <span>
          &nbsp;O: {tickData[0]?.data?.ohlc?.open?.toLocaleString() || "-"}{" "}
        </span>
        <span>
          &nbsp;H: {tickData[0]?.data?.ohlc?.high?.toLocaleString() || "-"}{" "}
        </span>
        <span>
          &nbsp;L: {tickData[0]?.data?.ohlc?.low?.toLocaleString() || "-"}{" "}
        </span>
        <span>
          &nbsp;C: {tickData[0]?.data?.ohlc?.close?.toLocaleString() || "-"}{" "}
        </span>
      </>
    );
  };
  const Tick = () => {
    const rowStyle = { height: "30px" }; // Adjust the row height
    const cellStyle = { padding: "4px 8px" }; // Adjust the cell padding
    return (
      <>
        <Table
          rowKey={"_id"}
          className="table tickTable"
          title={getNiftyPrice}
          dataSource={tickData?.slice(1)}
          columns={C.column}
          pagination={false}
          style={{ maxHeight: "300px", overflow: "auto" }} // Adjust maxHeight as needed
          components={{
            body: {
              row: (props) => (
                <tr {...props} style={{ ...props.style, ...rowStyle }}>
                  {props.children}
                </tr>
              ),
              cell: (props) => (
                <td {...props} style={{ ...props.style, ...cellStyle }}>
                  {props.children}
                </td>
              ),
            },
          }}
        />
      </>
    );
  };

  const renderCharts = () => {
    switch (current) {
      case "Tick":
        return <Tick />;
      case "Options":
        return (
          <>
            <StockPercentageChart
              indexType={"NIFTY"}
              chartType={"nsignal"}
              title={"Nifty Option Variance Movements"}
              selectedDate={selectedDate}
            />
            {/* <StockPercentageChart
              indexType={"NIFTY"}
              chartType={"signal"}
              title={"Nifty -D2 | D2 Option Variance Movements"}
            /> */}
          </>
        );
      case "Technicals":
        return <Heatmap />;
      case "Pulse":
        return <Pulse />;
      default:
        return null;
    }
  };
  const disabledDate = (current) => {
    return current && current.isAfter(currentDate, "day"); // Prevent selecting future dates
  };

  return (
    <div className="user-container">
      <div className="user-sub-container">
        <div className="order-sub-container">
          <div className="users-list">
            {["Tick", "Options", "Technicals", "Pulse"].map((data) => (
              <button
                key={data}
                className={`custom ${current === data ? "activeButton" : ""}`}
                onClick={() => setCurrent(data)}
              >
                {data}
              </button>
            ))}
          </div>
          {current === "Options" && (
            <div className="date">
              <DatePicker
                placeholder="Select Date "
                className="date-picker"
                defaultValue={currentDate}
                onChange={(e) => {
                  if (e) {
                    setSelectedDate(e.format("YYYY-MM-DD"));
                  }
                }}
                disabledDate={disabledDate} // Disable future dates
              />
            </div>
          )}
        </div>
      </div>
      <div className="chart_data">{renderCharts()}</div>
    </div>
  );
}

export default Chart;
