import axios from "axios";
import { message } from "antd";
import { jwtDecode } from "jwt-decode";

export const login = (payload) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_HOST}/auth/login`,
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: JSON.stringify(payload),
  };
  return axios
    .request(config)
    .then((response) => {
      localStorage.setItem("token", response.data.token);
      window.location.reload();
    })
    .catch((error) => {
      message.error(error?.response?.data?.message || "Server is Down");
    });
};

const handleError = (error) => {
  if (error?.status === 401) {
    message.error(error?.response?.data?.message);
    localStorage.removeItem("token");
    window.location.reload();
  }
};

export const googleLogin = (payload) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_HOST}/auth/google/login`,
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    data: JSON.stringify(payload),
  };
  return axios
    .request(config)
    .then((response) => {
      localStorage.setItem("token", response.data.token);
      window.location.reload();
    })
    .catch((error) => {
      message.error(error?.response?.data?.message || "Server is Down");
    });
};

export const listUsers = () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_HOST}/users/list`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      message.error(error?.response?.data?.message || "Server is Down");
    });
};

export const verify = () => {
  const token = localStorage.getItem("token");
  if (!token) return false;
  const decode = jwtDecode(token);
  if (Date.now() > decode?.exp * 1000) return false;
  return true;
};

export const stopAllTrade = () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_HOST}/users/stop/trade`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      message.error(error?.response?.data?.message || "Server is Down");
    });
};

export const autoLogin = () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_HOST}/users/autologin`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      message.error(error?.response?.data?.message || "Server is Down");
    });
};

export const resetUser = () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_HOST}/users/start/trade`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      message.error(error?.response?.data?.message || "Server is Down");
    });
};

export const listOrders = (id) => {
  if (!id) return;
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_HOST}/users/list/orders`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: JSON.stringify({ id: id }),
  };
  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      message.error(error?.response?.data?.message || "Server is Down");
    });
};

export const listConsole = (id) => {
  if (!id) return;
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_HOST}/users/list/console`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: JSON.stringify({ id: id }),
  };
  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      message.error(error?.response?.data?.message || "Server is Down");
    });
};

export const syncConsole = () => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_HOST}/users/list/console/sync`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: JSON.stringify({ id: "id" }),
  };
  return axios
    .request(config)
    .then((response) => {
      message.success("Sync Operation Success! it will take few seconds");
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      message.error(error?.response?.data?.message || "Server is Down");
    });
};

export const listProfits = () => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_HOST}/users/list/console/profit`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: JSON.stringify({ id: "id" }),
  };
  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      message.error(error?.response?.data?.message || "Server is Down");
    });
};

export const getBalance = (id) => {
  if (!id) return;
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_HOST}/users/balance`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: JSON.stringify({ id: id }),
  };
  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      message.error(error?.response?.data?.message || "Server is Down");
    });
};

export const createOrders = (payload) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_HOST}/users/orders/create`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: JSON.stringify(payload),
  };
  return axios
    .request(config)
    .then(() => {
      // message.info("Order Operation Success!");
    })
    .catch((error) => {
      message.error(error?.response?.data?.message || "Server is Down");
      handleError(error);
    });
};

export const createConsole = (payload) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_HOST}/users/console/create`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: JSON.stringify(payload),
  };
  return axios
    .request(config)
    .then(() => {
      // message.info("Order Operation Success!");
    })
    .catch((error) => {
      message.error(error?.response?.data?.message || "Server is Down");
      handleError(error);
    });
};

export const updateExpiry = (payload) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_HOST}/users/update/expiry`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: JSON.stringify(payload),
  };
  return axios
    .request(config)
    .then(() => {
      message.info("Expiry Operation Success!");
    })
    .catch((error) => {
      message.error(error?.response?.data?.message || "Server is Down");
      handleError(error);
    });
};

export const deleteOrders = (id) => {
  if (!id) return;
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_HOST}/users/order/delete`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: JSON.stringify({ id: id }),
  };
  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      message.error(error?.response?.data?.message || "Server is Down");
    });
};

export const createUsers = (payload) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_HOST}/users/create`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: JSON.stringify(payload),
  };
  return axios
    .request(config)
    .then(() => {
      message.info("User Operation Success!");
    })
    .catch((error) => {
      message.error(error?.response?.data?.message || "Server is Down");
      handleError(error);
    });
};

export const deleteUser = (id) => {
  if (!id) return;
  let config = {
    method: "delete",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_HOST}/users/delete`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: JSON.stringify({ id: id }),
  };
  return axios
    .request(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      handleError(error);
      message.error(error?.response?.data?.message || "Server is Down");
    });
};

export const shutdownPl = () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_PL_SERVER_END_POINT}/users/stop/server`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  return axios.request(config).catch((error) => {
    handleError(error);
    message.error(error?.response?.data?.message || "Server is Down");
  });
};
export const shutdownAll = async () => {
  // await shutdownPl();
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_HOST}/users/stop/server`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  return axios
    .request(config)
    .then(() => {
      localStorage.removeItem("token");
      window.location.reload();
    })
    .catch((error) => {
      handleError(error);
      message.error(error?.response?.data?.message || "Server is Down");
    });
};

export const getIntialData = async (selectedDate) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_PL_SERVER_END_POINT}/users/gdata?date=${selectedDate}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  return axios.request(config).catch((error) => {
    handleError(error);
    message.error(error?.response?.data?.message || "Server is Down");
  });
};
