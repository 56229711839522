import { Tag, Button, Popconfirm } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
// eslint-disable-next-line
export default {
  column: (handleEdit, handleDelete) => [
    {
      title: "Sl",
      dataIndex: "sl",
      key: "name",
      render: (_id, _data, idx) => <span>{idx + 1}</span>,
    },
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
      width: 70,
      render: (data) => (
        <Tag
          style={{ width: "40px", textAlign: "center" }}
          color={data === "BUY" ? "green" : "red"}
        >
          {data}
        </Tag>
      ),
    },
    {
      title: "Sym",
      dataIndex: "symbol",
      key: "symbol",
    },
    {
      title: "Exp",
      dataIndex: "expiry",
      key: "expiry",
      width: 80,
      render: (expiry, data) => {
        return (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ marginRight: "8px" }}>{expiry}</span>
            {data?.isVerified ? (
              <span
                style={{
                  backgroundColor: "green",
                  borderRadius: "50%",
                  padding: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CheckCircleOutlined style={{ color: "white", fontSize: 16 }} />
              </span>
            ) : (
              <span
                style={{
                  backgroundColor: "red",
                  borderRadius: "50%",
                  padding: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CloseCircleOutlined style={{ color: "white", fontSize: 16 }} />
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 70,
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
      width: 70,
    },
    {
      title: "Var",
      dataIndex: "variance",
      key: "variance",
      width: 70,
    },
    {
      title: "MVar",
      dataIndex: "marginVariance",
      key: "marginVariance",
      width: 70,
    },
    {
      title: "MQt",
      dataIndex: "marginQunatity",
      key: "marginQunatity",
      width: 70,
    },
    {
      title: "Strat",
      dataIndex: "stratergy",
      key: "stratergy",
      render: (data) => (
        <Tag style={{ width: "40px", textAlign: "center" }} color={"blue"}>
          {data}
        </Tag>
      ),
    },
    {
      title: "Live",
      dataIndex: "isLiveToTrade",
      key: "isLiveToTrade",
      render: (data) => (
        <Tag
          style={{ width: "55px", textAlign: "center" }}
          color={data ? "green" : "red"}
        >
          {data ? "Live" : "Offline"}
        </Tag>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (data) => (
        <Tag
          style={{ textAlign: "center" }}
          color={data !== "REJECTED" ? "green" : "red"}
        >
          {data}
        </Tag>
      ),
    },
    {
      title: "-",
      dataIndex: "_id",
      key: "_id",
      render: (data) => (
        <Button type="primary" onClick={() => handleEdit(data)}>
          Edit
        </Button>
      ),
    },
    {
      title: "-",
      dataIndex: "_id",
      key: "_id",
      width: 50,
      render: (data) => (
        <Popconfirm
          title={"Deleting Order"}
          description={"Are you Sure to Delete?"}
          onConfirm={() => handleDelete(data)}
          okText="Yes"
        >
          <Button type="primary" danger>
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ],
};
