import React, { useRef, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import "./../style.css";
import { listProfits } from "../../../utils/api";
// Register required Chart.js components
ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler // Enables the area fill effect
);

const AreaChart = ({ data, users }) => {
  const [pData, setPdata] = useState({});

  useEffect(() => {
    async function fetch() {
      const res = await listProfits();
      setPdata(res?.[0]);
    }
    fetch();
  }, []);

  const chartRef = useRef(null);
  // Function to create a gradient effect for the fill
  const getGradient = (ctx, color) => {
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, color); // Top color
    gradient.addColorStop(1, "rgba(255, 255, 255, 0.1)"); // Fades to transparent
    return gradient;
  };

  // Extract unique dates for X-axis
  const labels = [
    ...new Set(
      data?.flatMap((user) => user?.records?.map((record) => record.date))
    ),
  ];

  const formatNumber = (num) => {
    if (num >= 1_00_00_000 || num <= -1_00_00_000) {
      return (num / 1_00_00_000).toFixed(1) + "Cr"; // Crore
    } else if (num >= 1_00_000 || num <= -1_00_000) {
      return (num / 1_00_000).toFixed(1) + "L"; // Lakh
    } else if (num >= 1_000 || num <= -1_000) {
      return (num / 1_000).toFixed(1) + "K"; // Thousand
    }
    return num?.toLocaleString("en-IN") || num; // Default formatting
  };
  const xaxis = new Array(10).fill(1).map((i, idx) => {
    const t = new Date();
    t.setDate(t.getDate() + idx + 1);
    return t.toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    });
  });
  // Prepare dataset for each user
  const transformedData = {
    labels: [...labels, "current", ...xaxis].sort(
      (a, b) => new Date(a) - new Date(b)
    ),
    datasets: data?.map((user, index) => {
      const ctx = chartRef.current?.ctx;
      const borderColor = getRandomColor(index);
      const backgroundColor = ctx ? getGradient(ctx, borderColor) : borderColor;

      // Step 1: Create a map of available records
      let userRecords = user?.records || [];
      let capitalMap = new Map(userRecords.map((r) => [r.date, r.capital]));

      // Step 2: Sort available dates for consistency
      let sortedLabels = labels.sort((a, b) => new Date(a) - new Date(b));

      // Step 3: Fill missing dates
      let filledData = [];
      let lastKnownCapital = userRecords.length ? userRecords[0].capital : 0; // Use first available capital

      sortedLabels.forEach((date) => {
        if (capitalMap.has(date)) {
          lastKnownCapital = capitalMap.get(date); // Use actual data if available
        }
        filledData.push(lastKnownCapital); // Fill missing dates with last known value
      });

      // Step 4: Add 'Current' value
      const lastRecord = userRecords[userRecords.length - 1] || {};
      filledData.push(
        (lastRecord?.capital || 0) +
          (lastRecord?.profit || 0) -
          (lastRecord?.charges || 0)
      );

      return {
        label: users.find((i) => i._id === user._id)?.name || "-",
        data: filledData,
        borderColor,
        backgroundColor,
        tension: 0.4,
        pointRadius: 4,
        pointBackgroundColor: borderColor,
      };
    }),
  };

  // Function to generate random colors for lines
  function getRandomColor(idx) {
    return idx % 2 === 0 ? "#d4603b" : "rgb(47, 74, 249)";
  }

  return (
    <div className="console-chart-container">
      <div className="nt-pl">
        {" "}
        <h5>
          Realised P&L &nbsp;{":"} &nbsp;
          <span className={pData?.realized > 0 ? "profit" : "loss"}>
            {formatNumber(pData?.realized)}
          </span>
        </h5>{" "}
        &nbsp; &nbsp;
        <h5>
          Charges & taxes &nbsp;{":"} &nbsp;
          <span className="loss">-{formatNumber(pData?.charges)}</span>
        </h5>
        &nbsp; &nbsp;
        <h5>
          Net realised P&L &nbsp;{":"} &nbsp;
          <span className={pData?.net > 0 ? "profit" : "loss"}>
            {formatNumber(pData?.net)}
          </span>
        </h5>
      </div>
      <div className="console" style={{ width: "100%", height: "700px" }}>
        <Line
          ref={chartRef}
          data={transformedData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: true,
                position: "top",
              },
              tooltip: {
                mode: "index",
                intersect: false,
              },
            },
            scales: {
              x: {
                title: { display: true, text: "Date" },
                grid: { display: false },
              },
              y: {
                title: { display: true, text: "Capital" },
                grid: { color: "rgba(200, 200, 200, 0.2)" },
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default AreaChart;
