/* eslint-disable */
import { Button, Table } from "antd";
import "./style.css";
import { useEffect, useState } from "react";
import {
  listUsers,
  deleteOrders,
  listConsole,
  syncConsole,
} from "../../utils/api";
import C from "./constant";
import LineChart from "./forms/LineChart";

function Orders() {
  const [currentUser, setCurrentUser] = useState(null);
  const [user, setUsers] = useState([{ _id: "all", name: "Dashaboard" }]);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetch() {
      const users = await listUsers();
      setUsers([...user, ...users]);
      setCurrentUser(user[0]?._id);
    }
    fetch();
  }, []);

  useEffect(() => {
    async function fetch() {
      const orders = await listConsole(currentUser);
      setLoading(false);
      setOrders(orders);
    }
    setOrders([]);
    fetch();
  }, [currentUser]);

  const formatNumber = (num) => {
    if (num >= 1_00_00_000 || num <= -1_00_00_000) {
      return (num / 1_00_00_000).toFixed(1) + "Cr"; // Crore
    } else if (num >= 1_00_000 || num <= -1_00_000) {
      return (num / 1_00_000).toFixed(1) + "L"; // Lakh
    } else if (num >= 1_000 || num <= -1_000) {
      return (num / 1_000).toFixed(1) + "K"; // Thousand
    }
    return num?.toLocaleString("en-IN") || num; // Default formatting
  };

  const handleDelete = async (id) => {
    setLoading(true);
    await deleteOrders(id);
    const orders = await listConsole(currentUser);
    setOrders(orders);
    setLoading(false);
  };
  const nt = orders?.reduce((a, b) => a + b.profit, 0);
  const charges = orders?.reduce((a, b) => a + b.charges, 0);
  const totalProfit = nt - charges;

  return (
    <div className="order-container">
      <div className="order-sub-container">
        <div className="users-list">
          {user?.map((data) => {
            return (
              <button
                className={`custom ${
                  currentUser === data._id ? "activeButton" : ""
                }`}
                onClick={() => setCurrentUser(data._id)}
              >
                {data.name.toLocaleLowerCase().charAt(0).toUpperCase() +
                  data.name.toLocaleLowerCase().slice(1)}
              </button>
            );
          })}
        </div>
        {currentUser === "all" && (
          <div className="order-list">
            <Button
              type="primary"
              className="create"
              onClick={async () => {
                await syncConsole();
              }}
            >
              Sync Console
            </Button>
          </div>
        )}
      </div>
      {currentUser && currentUser !== "all" && (
        <div className="table-content">
          <div className="order-header">
            <h5>
              Realised P&L &nbsp;{":"} &nbsp;
              <span className={nt > 0 ? "profit" : "loss"}>
                {formatNumber(nt)}
              </span>
            </h5>{" "}
            &nbsp; &nbsp;
            <h5>
              Charges & taxes &nbsp;{":"} &nbsp;
              <span className="loss">-{formatNumber(charges)}</span>
            </h5>
            &nbsp; &nbsp;
            <h5>
              Net realised P&L &nbsp;{":"} &nbsp;
              <span className={totalProfit > 0 ? "profit" : "loss"}>
                {formatNumber(totalProfit)}
              </span>
            </h5>
          </div>
          <Table
            rowKey={"_id"}
            loading={loading}
            className={"table-order"}
            dataSource={orders}
            columns={C.column(orders?.length, handleDelete)}
            pagination={false}
            scroll={
              orders?.length > 10
                ? {
                    x: "max-content",
                    y: 580, // Set the vertical scroll height as required
                  }
                : {
                    x: "max-content",
                  }
            }
          />
        </div>
      )}
      {currentUser === "all" && orders?.length && (
        <LineChart data={orders} users={user} />
      )}
    </div>
  );
}

export default Orders;
