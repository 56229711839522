import { Form, Input, InputNumber, Select } from "antd";

export function CreateForm({ form, handleSubmit }) {
  return (
    <Form
      form={form}
      name="loginForm"
      onFinish={handleSubmit}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      style={{
        maxWidth: 600,
      }}
    >
      <Form.Item
        name={"order"}
        label="Select Order "
        initialValue={"BUY"}
        rules={[
          {
            required: true,
            message: "Order is required",
          },
        ]}
      >
        <Select placeholder="Select Order">
          <Select.Option value="BUY">BUY</Select.Option>
          <Select.Option value="SELL">SELL</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="expiry"
        label="Enter Expiry"
        rules={[
          {
            required: true,
            message: "Please add expiry!",
          },
        ]}
      >
        <Input placeholder="Enter your expiry" />
      </Form.Item>

      <Form.Item
        name="quantity"
        label="Enter Quantity"
        rules={[
          {
            required: true,
            message: "Please Quantity!",
          },
        ]}
      >
        <InputNumber
          min={0}
          style={{ width: "100%" }}
          max={Infinity}
          placeholder="Enter your Quantity"
        />
      </Form.Item>

      <Form.Item
        name="variance"
        label="Enter Variance"
        rules={[
          {
            required: true,
            message: "Please add Variance!",
          },
        ]}
      >
        <InputNumber
          min={0}
          value={0}
          style={{ width: "100%" }}
          max={Infinity}
          placeholder="Enter your Variance"
        />
      </Form.Item>

      <Form.Item
        name="marginVariance"
        label="Enter M.Var"
        initialValue={0}
        rules={[
          {
            required: true,
            message: "Please add M-Variance!",
          },
        ]}
      >
        <InputNumber
          min={0}
          value={0}
          style={{ width: "100%" }}
          max={Infinity}
          placeholder="Enter your M-Variance!"
        />
      </Form.Item>

      <Form.Item
        name="marginQunatity"
        label="Enter M.Qty"
        initialValue={0}
        rules={[
          {
            required: true,
            message: "Please add M-Quantity!",
          },
        ]}
      >
        <InputNumber
          min={0}
          style={{ width: "100%" }}
          max={Infinity}
          placeholder="Enter your M-Quantity"
        />
      </Form.Item>
      <Form.Item
        name={"status"}
        label="Select Status"
        initialValue={"WAITING"}
        rules={[
          {
            required: true,
            message: "Status is Required",
          },
        ]}
      >
        <Select placeholder="Select status">
          <Select.Option value={"PENDING"}>PENDING</Select.Option>
          <Select.Option value={"WAITING"}>WAITING</Select.Option>
          <Select.Option value={"SL"}>SL</Select.Option>
          <Select.Option value={"BUY"}>BUY</Select.Option>
          <Select.Option value={"SELL"}>SELL</Select.Option>
          <Select.Option value={"LIMIT"}>LIMIT</Select.Option>
          <Select.Option value={"BUY_SL"}>BUY-SL</Select.Option>
          <Select.Option value={"SELL_SL"}>SELL-SL</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name={"_id"} style={{ height: "1px" }}></Form.Item>
    </Form>
  );
}

export default CreateForm;
