import { Button, Table, Modal, Form, Popconfirm } from "antd";
import "./style.css";
import { useEffect, useState } from "react";
import {
  listOrders,
  listUsers,
  createOrders,
  deleteOrders,
  updateExpiry,
  createUsers,
} from "../../utils/api";
import C from "./constant";
import CreateForm from "./forms/createform";
import ExpiryForm from "./forms/expiryForma";
import { message } from "antd";

function Orders() {
  const [currentUser, setCurrentUser] = useState(null);
  const [user, setUsers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [expiryShow, setExpiryShow] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    async function fetch() {
      const users = await listUsers();
      setUsers(users);
      setCurrentUser(users?.[0]?._id);
    }
    fetch();
  }, []);

  useEffect(() => {
    async function fetch() {
      const orders = await listOrders(currentUser);
      setLoading(false);
      setOrders(orders);
    }
    fetch();
  }, [currentUser]);

  function splitIntoMultiples(num, order, multiple = 75, maxChunk = 1800) {
    let result = [];
    const o = {
      symbol: "NIFTY",
      kVariance: 250,
      stratergy: "KS",
      sl: 44,
      target: 44,
      isLiveToTrade: false,
      status: "WAITING",
    };

    while (num > 0) {
      if (num >= maxChunk) {
        result.push([
          {
            type: "CE",
            ...o,
            ...order,
            marginQunatity: order.marginQunatity
              ? maxChunk
              : order.marginQunatity,
            quantity: maxChunk,
          },
          {
            type: "PE",
            ...o,
            ...order,
            marginQunatity: order.marginQunatity
              ? maxChunk
              : order.marginQunatity,
            quantity: maxChunk,
          },
        ]);
        num -= maxChunk;
      } else {
        let nearest = Math.floor(num / multiple) * multiple;
        result.push([
          {
            type: "CE",
            ...o,
            ...order,
            marginQunatity: order.marginQunatity
              ? nearest
              : order.marginQunatity,
            quantity: nearest,
          },
          {
            type: "PE",
            ...o,
            ...order,
            marginQunatity: order.marginQunatity
              ? nearest
              : order.marginQunatity,
            quantity: nearest,
          },
        ]);
        num -= nearest;
      }
    }

    return result.flat(Infinity);
  }

  function roundToNearest(num, multiple = 75) {
    return Math.round(num / multiple) * multiple;
  }

  const handleSubmit = async (payload) => {
    setLoading(true);
    const { quantity, order, _id = null } = payload;
    if (_id) {
      const orderss = await listOrders(currentUser);
      const ot = orderss.find((i) => i._id === _id);
      const { symbol = null, type = null } = ot;
      await createOrders({ userId: currentUser, symbol, type, ...payload });
      message.info(`Orders updated Successfully!`);
    } else {
      const totalOrders = splitIntoMultiples(roundToNearest(quantity), payload);
      const orderPromises = totalOrders.map((order) =>
        createOrders({ userId: currentUser, ...order })
      );
      // Wait for all orders to be created
      await Promise.all(orderPromises);
      message.info(
        `${totalOrders.length} ${order} Orders Created Successfully!`
      );
    }

    // Now fetch updated orders
    const orders = await listOrders(currentUser);
    const baseQuantity = orders
      ?.filter((i) => i.order === "BUY")
      ?.reduce((a, b) => a + b.quantity / 2, 0);
    const users = await listUsers();
    const user = users.find((i) => i._id === currentUser);
    const updatedUser = {
      ...user,
      baseQuantity,
    };
    await createUsers(updatedUser);
    setOrders(orders);
    form.resetFields();
    setShow(false);
    setLoading(false);
  };

  const handleExpirySubmit = async (payload) => {
    setLoading(true);
    await updateExpiry(payload);
    const orders = await listOrders(currentUser);
    form.resetFields();
    setOrders(orders);
    setExpiryShow(false);
    setLoading(false);
  };

  const handleEdit = (id) => {
    form.setFieldsValue(orders.find((i) => i._id === id));
    setShow(true);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    await deleteOrders(id);
    const orders = await listOrders(currentUser);
    setOrders(orders);
    setLoading(false);
  };

  const deleteAll = async () => {
    setLoading(true);
    const orderPromises = orders.map((order) => {
      return deleteOrders(order._id);
    });
    await Promise.all(orderPromises);
    const order = await listOrders(currentUser);
    setOrders(order);
    setLoading(false);
  };
  return (
    <div className="order-container">
      <div className="order-sub-container">
        <div className="users-list">
          {user?.map((data) => {
            return (
              <button
                className={`custom ${
                  currentUser === data._id ? "activeButton" : ""
                }`}
                onClick={() => setCurrentUser(data._id)}
              >
                {data.name.toLocaleLowerCase().charAt(0).toUpperCase() +
                  data.name.toLocaleLowerCase().slice(1)}
              </button>
            );
          })}
        </div>
        <div className="order-list">
          <Button
            type="primary"
            className="create"
            onClick={() => setShow(true)}
          >
            Create Order
          </Button>

          <Button
            type="primary"
            className="create"
            onClick={() => setExpiryShow(true)}
          >
            Add Expiry
          </Button>

          <Popconfirm
            title={"Deleting all Orders"}
            description={"Are you Sure to Delete?"}
            onConfirm={deleteAll}
            okText="Yes"
          >
            {" "}
            <Button type="primary" className="create" danger>
              Delete All
            </Button>
          </Popconfirm>
        </div>
      </div>
      <div className="table-content">
        <div className="order-header">
          <h5>
            Total Buy QTY &nbsp;{":"} &nbsp;
            <span style={{ color: "#4988ee" }}>
              {orders
                ?.filter((i) => i.order === "BUY")
                ?.reduce((a, b) => a + b.quantity / 2, 0)
                ?.toLocaleString("en-IN")}
            </span>
          </h5>{" "}
          &nbsp; &nbsp;
          <h5>
            Total SELL QTY &nbsp;{":"} &nbsp;
            <span style={{ color: "#4988ee" }}>
              {orders
                ?.filter((i) => i.order === "SELL")
                ?.reduce((a, b) => a + b.quantity / 2, 0)
                ?.toLocaleString("en-IN")}
            </span>
          </h5>
        </div>
        <Table
          rowKey={"_id"}
          loading={loading}
          className={"table-order"}
          dataSource={orders}
          columns={C.column(handleEdit, handleDelete)}
          pagination={false}
          scroll={
            orders?.length > 10
              ? {
                  x: "max-content",
                  y: 580, // Set the vertical scroll height as required
                }
              : {
                  x: "max-content",
                }
          }
        />
        <Modal
          title={"Order Form"}
          open={show}
          onOk={() => form.submit()}
          onCancel={() => {
            setShow(false);
            form.resetFields();
          }}
          destroyOnClose
        >
          <div className="order-form-container">
            <CreateForm form={form} handleSubmit={handleSubmit} />
          </div>
        </Modal>

        <Modal
          title={"Expiry Form"}
          open={expiryShow}
          onOk={() => form.submit()}
          onCancel={() => {
            setExpiryShow(false);
            form.resetFields();
          }}
          destroyOnClose
        >
          <div className="order-form-container2">
            <ExpiryForm form={form} handleSubmit={handleExpirySubmit} />
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default Orders;
